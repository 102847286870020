export function onInitCookieConsent({ market, language, serverConfig }) {
  if(window.Cypress) return

  addConsentScript()
  window.addEventListener('cms-web-consent-root-loaded', onConsentLoaded)

  function addConsentScript() {
    const script = document.createElement('script')
    script.src = `${serverConfig.cookieConsent}?t=${Date.now()}`
    script.async = true
    document.head.appendChild(script)
  }

  function onConsentLoaded() {
    const isTrackingAllowed = window.native && window.native.getIsTrackingAllowed && window.native.getIsTrackingAllowed();

    if (typeof isTrackingAllowed === 'undefined') renderConsent()
    else window.CWC.setCookie(isTrackingAllowed)

    function renderConsent() {
      window.CWC.render({
        env: 'miki',
        link: false,
        locale: `${language}-${market}`.toLocaleLowerCase(),
        tab: ''
      })
    }
  }
}
