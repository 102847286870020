/* Clamps a number within a given range. With the expression
`clamp(100, 200, x)` the results are as follows:

    100     if x < 100
     x      if 100 <= x < 200
    200     if 200 <= x
 */
// eslint-disable-next-line import/prefer-default-export
export function clamp(low, high, number) {
  if (number < low) {
    return low
  } else if (number > high) {
    return high
  } else {
    return number
  }
}
