export const logError = (error) => {
  if (!window.digidata || !window.digiData.website) return

  const errors = window.digiData.website.errors || []

  errors.push(error)

  window.digiData.website.errors = errors
}

export const setDigiData = (digiData) => {
  const errors = (window.digiData && window.digiData.website.errors) || []
  digiData.website.errors = errors

  window.digiData = digiData
}

export const onWindowError = (statusText, url, line, col, error) => {
  const message = ((error && error.stack) || '\n').split('\n')[1].trim()
  logError({ name: statusText, message })
}
