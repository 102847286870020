export const VEHICLE_STORAGE_KEY = 'memorizedFirstSelectedVehicle'

export default function getMemorizedFirstSelectedVehicle() {
  try {
    if (!window.localStorage || !window.localStorage.getItem) return null

    return JSON.parse(
      window.localStorage.getItem('memorizedFirstSelectedVehicle')
    )
  } catch (e) {
    return null
  }
}
